import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { AiChip, type NavLinkData } from '@ltvco/refresh-lib/v1';

interface PropertyNavLinkData extends NavLinkData {
  visible: boolean;
}
export const getPropertyNavLinkData = (
  potentialOwnersLength: number,
  possibleResidentsLength: number,
  buildingPermitsLength: number,
  deedsLength: number,
  liensLength: number,
  propertyDetailsLength: number,
  propertyValuesLength: number,
  equitiesCount: number,
  schoolsCount: number
) => {
  const isInPropertySmartInsightsVariation = useFeatureIsOn('prop-2296');

  return [
    {
      href: '#overview',
      sectionId: '#overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
      name: 'overview',
    },
    (isInPropertySmartInsightsVariation && {
      href: '#property-smart-insights',
      sectionId: '#property-smart-insights',
      text: 'Property Smart Insights',
      visible: true,
      reportItemsCount: 0,
      notificationSection: 'property smart insights',
      icon: <AiChip />,
      showIcon: true,
      name: 'property-smart-insights',
    }) as PropertyNavLinkData,
    {
      href: '#possible-owners-section',
      sectionId: '#possible-owners-section',
      text: 'Possible Owners',
      reportItemsCount: potentialOwnersLength,
      notificationSection: 'owners',
      name: 'possible-owners',
    },
    {
      href: '#possible-residents-section',
      sectionId: '#possible-residents-section',
      text: 'Possible Residents',
      reportItemsCount: possibleResidentsLength,
      notificationSection: 'residents',
      name: 'possible-residents',
    },
    {
      href: '#lot-and-building-details-section',
      sectionId: '#lot-and-building-details-section',
      text: 'Property Details',
      reportItemsCount: propertyDetailsLength,
      notificationSection: 'lot_details',
      notificationSectionMapping: [
        { text: 'lot_details', href: '#lot-and-building-details-section' },
        { text: 'values', href: '#assessed-value-and-taxes-section' },
      ],
      name: 'property-details',
    },
    {
      href: '#building-permits-section',
      sectionId: '#building-permits-section',
      text: 'Building Permits',
      reportItemsCount: buildingPermitsLength,
      notificationSection: 'permits',
      name: 'building-permits',
    },
    {
      href: '#deeds-section',
      sectionId: '#deeds-section',
      text: 'Deeds',
      reportItemsCount: deedsLength,
      notificationSection: 'deeds',
      name: 'deeds',
    },
    {
      href: '#liens-section',
      sectionId: 'liens-section',
      text: 'Liens',
      reportItemsCount: liensLength,
      notificationSection: 'liens',
      name: 'liens',
    },
    {
      href: '#estimated-value-section',
      sectionId: '#estimated-value-section',
      text: 'Property Valuation',
      reportItemsCount: propertyValuesLength,
      notificationSection: 'valuation',
      name: 'property-valuation',
    },
    {
      href: '#equity-and-loans-section',
      sectionId: '#equity-and-loans-section',
      text: 'Equity & Loans',
      reportItemsCount: equitiesCount,
      notificationSection: 'equity_loans',
      name: 'equity-&-loans',
    },
    {
      href: '#schools-nearby-section',
      sectionId: '#schools-nearby-section',
      text: 'Schools & Area',
      reportItemsCount: schoolsCount,
      notificationSection: 'schools',
      notificationSectionMapping: [
        { text: 'schools', href: '#schools-nearby-section' },
        { text: 'parcel_address', href: '#demographics-section' },
      ],
      name: 'schools-&-area',
    },
    {
      href: '#rewards-section',
      sectionId: '#rewards-section',
      text: 'Member Perks',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'memberPerks',
      name: 'member-perks',
    },
  ].filter(Boolean) as PropertyNavLinkData[];
};
